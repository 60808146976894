@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");

:root {
	--BrandBlue: #053e89;
	--BrandGreen: #00bf63;
	--BrandBlack: #000000;
	--BrandDarkGray: #1b1b1b;
	--BrandWhite: #ffffff;
	--BrandLight: #f2f2f2;
	--BrandLightGray: #707070;
	--BrandLightGreen: #daede4;

	--BrandRbwRed: #ff0000;
	--BrandRbwOrange: #b47500;
	--BrandRbwYellow: #8b8b00;
	--BrandRbwGreen: #04924d;
	--BrandRbwBlue: #053e89;
	--BrandRbwIndigo: #4b0082;
	--BrandRbwViolet: #ee82ee;
}
html {
	position: relative;
	min-height: 100%;
}
body {
	margin-bottom: 320px !important;
}
.primary {
	color: var(--BrandBlue);
}

.secondary {
	color: var(--BrandGreen);
}

.strikePrice {
	color: var(--BrandRbwRed);
	text-decoration: line-through;
}

.btn {
	font-size: 14px !important;
	padding: 8px 20px !important;
}

.btn-primary {
	background-color: var(--BrandBlue) !important;
	border-color: var(--BrandBlue) !important;
}

.btn-outline-primary {
	color: var(--BrandBlue) !important;
	border-color: var(--BrandBlue) !important;
}

.btn-secondary,
.bg-secondary {
	background-color: var(--BrandGreen) !important;
}

.btn-secondary {
	border: 1px solid var(--BrandGreen) !important;
}

.badge {
	font-weight: normal !important;
	padding: 4px 10px !important;
	background-color: var(--BrandGreen) !important;
	text-transform: uppercase;
	font-size: 12px !important;
}

body {
	font-family: "Open Sans", sans-serif !important;
	font-size: 14px !important;
	color: var(--BrandBlack) !important;
}
.alert {
	padding: 8px 12px;
	margin-bottom: 0;
	margin-top: 12px !important;
}

h1 {
	font-size: 40px !important;
}

h2 {
	font-size: 28px !important;
	font-weight: bold !important;
}

h3 {
	font-size: 24px !important;
	font-weight: bold !important;
}

h4 {
	font-size: 22px !important;
	font-weight: bold !important;
}

h5 {
	font-size: 16px !important;
	font-weight: bold !important;
}

.muted {
	color: var(--BrandLightGray);
}

.float-right {
	float: right;
}

.form-select {
	font-size: 0.9rem !important;
}

.font14 {
	font-size: 14px;
}

.font16 {
	font-size: 14px;
}

.font20 {
	font-size: 20px;
}

.nav-tabs {
	--bs-nav-tabs-border-color: var(--BrandBlue) !important;
	--bs-nav-tabs-border-radius: 0.2rem !important;
	--bs-nav-tabs-link-hover-border-color: var(--BrandBlue) var(--BrandBlue) var(--BrandBlue) !important;
	--bs-nav-tabs-link-active-color: var(--BrandGreen) !important;
	--bs-nav-tabs-link-active-border-color: var(--BrandBlue) var(--BrandBlue) var(--BrandWhite) !important;
}

.nav-tabs li {
	margin-right: 10px;
}

.nav-tabs li .nav-link {
	padding: 8px 20px !important;
	color: var(--BrandBlue) !important;
}

.nav-tabs li .nav-link:hover {
	background: var(--BrandBlue) !important;
	color: var(--BrandWhite) !important;
}

.search-bar {
	background: var(--BrandLightGreen);
	padding: 15px;
	z-index: 111;
	position: fixed;
	top: 70px;
	width: 100%;
}

.form-control {
	font-size: 0.9rem !important;
}

.form-label {
	margin-bottom: 0.4rem !important;
}

.btn-sm {
	padding: 6px 14px !important;
}

.breadcrumb-item a {
	color: var(--BrandBlue) !important;
}

.search-icon {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='gray' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: 10px;
	padding-left: 36px !important;
}

.small-hide {
	display: flex;
}

.home-top-padding {
	padding-top: 70px;
}

.home-banner img {
	width: 100%;
}

.sop-details .carousel {
	height: auto !important;
}

.sop-details .carousel-control-next-icon,
.sop-details .carousel-control-prev-icon {
	background-color: var(--BrandGreen);
	width: 36px;
	height: 36px;
	padding: 10px;
	border-radius: 50%;
	display: block;
}

.sop-details .carousel-control-next-icon,
.sop-details .carousel-control-prev-icon {
	background-size: 60% 60%;
}

.sop-details .carousel-indicators [data-bs-target] {
	background-color: var(--BrandGreen);
}

.carousel-caption {
	color: var(--BrandBlack) !important;
	right: 61% !important;
	left: 1.5rem !important;
	text-align: left !important;
	padding-top: 1rem !important;
}

.carousel .start-per-month {
	position: absolute;
	left: 320px;
	bottom: 40px;
}

.home-banner .carousel-control-next,
.home-banner .carousel-control-prev {
	width: 6%;
	opacity: 0.5;
}

.carousel {
	height: 290px !important;
	background: var(--BrandBlack) !important;
}

.carousel-inner {
	height: 100% !important;
}

.carousel-item {
	height: 100% !important;
}

.carousel-caption {
	top: 2rem !important;
}

.carousel-caption small {
	color: var(--BrandBlue);
	font-weight: bold;
}
.carousel-caption h1 {
	min-height: 105px;
}

/*.kVtMtA {
  top: calc(-9% - 17.5px) !important;
  right: 52px !important;
  left: initial !important;
}
.bhRnqO {
  top: calc(-9% - 17.5px) !important;
}
*/
small {
	text-transform: uppercase;
	color: var(--BrandGreen);
	font-size: 14px;
}

.sopGuide {
	background: var(--BrandBlue);
	padding: 20px 0;
}

.sopGuide-Gray {
	background: var(--BrandLight);
	padding: 20px 0;
}

.sopGuide .card {
	margin-top: 30px !important;
	height: 85%;
}

.sopGuide .ic-cards {
	position: absolute;
	background: var(--BrandGreen);
	padding: 10px;
	border-radius: 24px;
	left: 18px;
	top: -22px;
}

.sopGuide .card-body {
	padding-top: 30px;
}

.sopGuide ul {
	margin-bottom: 0;
	padding-left: 18px;
}

.bghjHX {
	margin: 0 !important;
}

.navbar-collapse .navbar-nav .dropdown .btn-link {
	padding: 0 !important;
	text-decoration: none !important;
}

.navbar .dropdown-toggle::after {
	content: none !important;
}

.navbar .btn-link {
	padding: 3px 10px !important;
}

.navbar .dropdown-item {
	font-size: 14px !important;
}

.navbar .btn-link i.bi {
	font-size: 20px;
	color: var(--BrandBlue) !important;
}

.navbar .toast {
	position: fixed;
	z-index: 9999;
	top: 55px;
	right: 100px;
	background: var(--BrandWhite);
}

.navbar,
.our-services,
.sopGuide,
.we-offering,
.testimonial,
.footer,
.faq,
.search-bar {
	padding-left: 60px !important;
	padding-right: 60px !important;
}

.carousel-caption {
	left: 60px !important;
}

.card a {
	text-decoration: none !important;
}

a .card-body h5,
a .card-body p {
	color: var(--BrandDarkGray) !important;
}

.card {
	-webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
	border: 0 !important;
	margin: 4px !important;
}

.navbar {
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	background: var(--BrandWhite);
}

.nav-link {
	color: var(--BrandBlack) !important;
	padding: 4px 12px !important;
	position: relative;
}

.nav-link i.bi {
	font-size: 20px;
	color: var(--BrandBlue) !important;
}

.cart-order {
	background-color: var(--BrandGreen);
	color: var(--BrandWhite);
	position: absolute;
	padding: 2px 8px;
	border-radius: 16px;
	top: -3px;
	right: -3px;
	font-size: 12px;
}

.testimonial .card {
	margin: 30px 4px 4px 4px !important;
	padding-top: 25px;
	background: var(--BrandLightGreen);
	border: 0;
}

.testimonial .quote {
	position: absolute;
	left: 16px;
	top: -26px;
}

.testimonial .card-text {
	font-size: 13px;
}

.we-offering .card,
.cart .card {
	margin: 30px 4px 4px 4px !important;
	padding-top: 25px;
}

.we-offering h5 {
	min-height: 40px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.we-offering .doc,
.cart .doc {
	position: absolute;
	left: 16px;
	top: -26px;
}

.we-offering Badge,
.cart Badge {
	background: var(--BrandGreen) !important;
}

.we-offering a,
.cart a {
	color: var(--BrandBlue) !important;
	text-decoration: none;
}

.we-offering .price,
.price {
	font-size: 24px;
	font-weight: 500;
}

.we-offering .price span,
.price span {
	font-size: 14px;
}

.we-offering .price .strikeT,
.price .strikeT {
	text-decoration: line-through;
}

.we-offering .price .disc,
.price .disc {
	color: var(--BrandGreen);
}

.we-offering .bg-secondary {
	background-color: var(--BrandGreen) !important;
}

.we-offering .card-text,
.sop-list .card-text {
	min-height: 65px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.our-services .card-text {
	min-height: 84px;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.our-services .card-body h5 {
	min-height: 40px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.testimonial .card-text {
	min-height: 60px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.sopGuide h2 {
	color: var(--BrandWhite);
}

.sopGuide .guide {
	height: 40px;
	margin: auto 10px auto 0;
}

.sopGuide small {
	color: var(--BrandWhite);
}
.about-achived .card-body {
	padding: 10px;
}
.my-profile {
	width: 120px;
	height: 120px;
	margin: 0 auto;
	position: relative;
}

.my-profile-img {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	overflow: hidden;
	position: absolute;
	left: 0;
	top: 0;
}

.my-profile .profile-edit {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;
}

.my-profile .profile-edit .btn {
	padding: 10px 13px !important;
	border-radius: 20px;
}

.footer {
	padding-top: 30px;
	padding-bottom: 25px;
	font-size: 13px;
	background-color: var(--BrandDarkGray);
	color: var(--BrandLight);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.footer h5 {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 15px;
}

.footer li {
	list-style: none;
	margin-bottom: 5px;
}

.footer a {
	color: var(--BrandWhite);
	text-decoration: none;
}

.footer a:hover {
	color: var(--BrandGreen);
}

.footer .form-control {
	font-size: 14px;
	border-radius: 5px;
	padding: 8px 10px;
}

.whatappChat {
	position: fixed;
	bottom: 30px;
	right: 30px;
}
.whatappChat .btn {
	padding: 8px 8px !important;
	background: #3bc04d;
	border-radius: 50%;
	width: 48px;
	height: 48px;
	-webkit-box-shadow: 3px 7px 10px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 3px 7px 10px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 3px 7px 10px 0px rgba(0, 0, 0, 0.75);
}
.whatappChat img {
	width: 30px;
}
.whatappChat .btn:hover {
	background: #3bc04d;
}

.sm-d {
	display: none !important;
}

.deviceTabs {
	display: none;
}

.accordion {
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
}

.accordion-button {
	font-weight: 600;
	font-size: 0.9rem !important;
}

.accordion-button:not(.collapsed) {
	color: var(--BrandWhite) !important;
	background: var(--BrandBlue) !important;
}

.accordion-button::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='black' class='bi bi-plus-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E") !important;
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-dash-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E") !important;
}

.top-sops {
	padding: 15px 0;
}

.top-sops.card {
	margin: auto !important;
	font-size: 14px;
}

.top-sops a {
	color: var(--BrandBlue);
	text-decoration: none;
}

.top-sops .head {
	margin-left: 10px;
}

.top-sops i {
	font-size: 2rem;
	color: var(--BrandGreen);
}

.login {
	max-width: 400px;
	margin: 50px auto !important;
	padding: 30px 40px;
}

.login-logo {
	padding: 0 15% 20px;
}

.login-logo img {
	width: 100%;
}

.login a {
	color: var(--BrandBlue);
	text-decoration: none;
}

.login-bg {
	background: url(../src/assets/images/login-bg1.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	padding: 2.1% 25px;
}

.login-font {
	font-size: 13px;
}

.login .form-control {
	line-height: 2 !important;
}

.login .back-btn {
	top: 0;
	left: 20px;
	font-size: 35px;
}

.manageSOP {
	padding-left: 60px !important;
	padding-right: 60px !important;
}

.manageSOP .btn-link {
	padding: 0 5px !important;
}

.manageSOP .collapse .card {
	height: 100%;
}

.manageSOP .exp-col-btn {
	position: absolute;
	z-index: 1;
	width: auto;
	left: -13px;
	top: 48px;
}

.manageSOP textarea {
	display: none;
}

.manageSOP .form-floating textarea {
	display: block;
}

.manageSOP .exp-col-btn .btn {
	padding: 5px !important;
	border-radius: 50%;
	width: 33px;
}

.innerHead {
	background: var(--BrandBlue);
	height: 10px;
	position: relative;
	overflow: hidden;
	margin-bottom: 5px;
}

.innerHead h1 {
	/*bottom: 10px;*/
	line-height: 110px;
	left: 60px;
	position: absolute;
	color: var(--BrandWhite);
	display: none;
}

.formGroup {
	background: var(--BrandLight);
	padding: 10px;
	margin: 0 !important;
	border-radius: 10px;
}

.cart-list {
	background-color: var(--BrandLight);
	padding: 15px 0;
	border-radius: 0.375rem;
}

.cart-list hr {
	margin: 0.7rem 0;
}

.cart-list .tablet {
	background: var(--BrandLight);
	border: 1px solid var(--BrandLightGray);
	padding: 3px 10px;
	color: var(--BrandDarkGray);
	font-size: 12px;
	border-radius: 5px;
	margin-right: 5px;
}

.cart-list a {
	font-size: 13px;
	color: var(--BrandBlue);
}

.offer-code {
	padding: 10px;
	background: var(--BrandLightGreen);
	font-size: 12px;
	border-radius: 5px;
	margin-bottom: 5px;
	line-height: 14px;
}

.offer-code button,
.offer-float-btn button {
	padding: 0px !important;
	color: var(--BrandGreen);
	font-size: 13px;
	font-weight: bold;
	text-decoration: none;
}

.offer-float-btn button {
	position: absolute;
	right: 7px;
	top: 6px;
}

.order .pills a {
	border: 1px solid var(--BrandBlue);
	color: var(--BrandBlue);
	padding: 6px 20px;
	font-size: 14px;
	border-radius: 18px;
	text-decoration: none;
	display: inline-block;
	margin-right: 10px;
}

.order .pills a.active {
	background-color: var(--BrandBlue);
	color: var(--BrandWhite);
}

.order-placed {
	background-color: var(--BrandDarkGray);
	padding: 10px;
	margin: -15px -5px 0 0;
	color: var(--BrandWhite);
}

/*SOP arrow steps*/
.arrow-steps .step {
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	color: #666;
	cursor: default;
	margin: 0 3px;
	padding: 4px 1px 2px 18px;
	float: left;
	position: relative;
	background-color: #d9e3f7;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: background-color 0.2s ease;
	min-height: 66px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
	content: " ";
	position: absolute;
	top: 0;
	right: -17px;
	width: 0;
	height: 0;
	border-top: 33px solid transparent;
	border-bottom: 33px solid transparent;
	border-left: 17px solid #d9e3f7;
	z-index: 2;
	transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
	right: auto;
	left: 0;
	border-left: 17px solid #fff;
	z-index: 0;
}

.arrow-steps .step:first-child:before {
	border: none;
}

.arrow-steps .step:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
	position: relative;
}

.arrow-steps span {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

/*.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}*/

.arrow-steps .step.done span:before {
	opacity: 1;
	-webkit-transition: opacity 0.3s ease 0.5s;
	-moz-transition: opacity 0.3s ease 0.5s;
	-ms-transition: opacity 0.3s ease 0.5s;
	transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.orange {
	color: #fff;
	background-color: var(--BrandRbwOrange);
}

.arrow-steps .step.orange:after {
	border-left: 17px solid var(--BrandRbwOrange);
}

.arrow-steps .step.red {
	color: #fff;
	background-color: var(--BrandRbwRed);
}

.arrow-steps .step.red:after {
	border-left: 17px solid var(--BrandRbwRed);
}

.arrow-steps .step.yellow {
	color: #fff;
	background-color: var(--BrandRbwYellow);
}

.arrow-steps .step.yellow:after {
	border-left: 17px solid var(--BrandRbwYellow);
}

.arrow-steps .step.green {
	color: #fff;
	background-color: var(--BrandRbwGreen);
}

.arrow-steps .step.green:after {
	border-left: 17px solid var(--BrandRbwGreen);
}

.arrow-steps .step.blue {
	color: #fff;
	background-color: var(--BrandRbwBlue);
}

.arrow-steps .step.blue:after {
	border-left: 17px solid var(--BrandRbwBlue);
}

.arrow-steps .step.indigo {
	color: #fff;
	background-color: var(--BrandRbwIndigo);
}

.arrow-steps .step.indigo:after {
	border-left: 17px solid var(--BrandRbwIndigo);
}

.arrow-steps .step.violet {
	color: #fff;
	background-color: var(--BrandRbwViolet);
}

.arrow-steps .step.violet:after {
	border-left: 17px solid var(--BrandRbwViolet);
}

/*.mySOP-cont {
  border: 1px solid var(--BrandLight);
  padding: 15px;
}*/
.mySOP-logo {
	border: 1px solid var(--BrandLight);
	padding: 28px 5px;
}

.mySOP-cont ul {
	padding: 0px;
	margin: 0 0 0 20px;
	font-size: 13px;
	line-height: 17px;
}

.mySOP-cont ul li {
	margin-bottom: 10px;
	word-break: break-word;
	text-align: justify;
}
.mySOP-cont .formGroup {
	border-radius: 6px 6px 0 0;
	padding: 7px 10px;
	background: var(--BrandLightGreen);
}

.mySOP-controls .btn-link {
	padding: 2px 6px !important;
	font-size: 16px !important;
	font-weight: bold !important;
	margin-left: 10px;
	color: var(--BrandWhite);
	background-color: var(--BrandBlue);
}

.mySOP-cont .form-switch .form-check-input {
	height: 1.2em;
	width: 2.3em;
}
.form-check-input:checked {
	background-color: var(--BrandBlue) !important;
	border-color: var(--BrandBlue) !important;
}

/*Tree menu */
.tree-menu-cont ul {
	padding: 0;
	list-style: none;
	margin: 0;
}

.tree-menu-cont ul > li {
	padding: 6px 0;
}

.tree-menu-cont ul > li:hover {
	cursor: pointer;
	background: var(--BrandLightGreen);
}

.tree-menu-cont ul.tree-sop-file {
	padding: 1px 5px 1px 20px;
	/*background: var(--BrandLight);*/
}

.tree-menu-cont ul.tree-sop-file:hover {
	background: var(--BrandLightGreen);
	cursor: pointer;
}

.tree-menu-cont .tree-main-folder {
	min-height: 23px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.steps-divider .col {
	border-right: 1px solid var(--BrandLight);
}

.steps-divider .col:last-child {
	border-right: none;
}

.steps-head {
	background: var(--BrandLight);
	padding: 10px;
	font-weight: bold;
	color: var(--BrandDarkGray);
}

.mySOP-cont table th {
	background: var(--BrandLight);
}

.mySOP-cont table td {
	font-size: 13px;
}

.resource .article .card-body h5 {
	min-height: 58px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.resource .article .card-body i {
	font-size: 12px;
	color: var(--BrandLightGray);
}
.resource .article .card-body p {
	max-height: 65px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	line-height: 20px;
}
.resVideos h5 {
	min-height: 58px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.resource .extLink iframe {
	width: 100% !important;
	height: 750px !important;
}
.resVideos p {
	min-height: 84px;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.other-articles .card-body h5 {
	max-height: 65px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.other-articles .card-body p {
	max-height: 65px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.user-dropdown .dropdown-menu {
	right: 0 !important;
	left: auto !important;
}

.terms-cond ol {
	margin-left: 16px;
}

.terms-cond ol > li {
	margin-bottom: 20px;
	font-weight: bold;
}

.terms-cond ol > li p {
	font-weight: normal;
}

.terms-cond ul > li {
	margin-bottom: 5px;
	font-weight: normal;
}

.terms-cond ul {
	padding-left: 20px;
	margin-top: 5px;
}

.prv-policy p {
	margin-bottom: 20px;
}

.prv-policy h5 {
	font-size: 15px !important;
	margin-bottom: 5px;
}

.prv-policy ol {
	padding-left: 20px;
}

.prv-policy ol li {
	margin-top: 5px;
}

.notification-cards ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.notification-cards ul li {
	background: var(--BrandLight);
	border-radius: 5px;
	padding: 5px 30px 5px 10px;
	margin: 4px 0;
	font-size: 14px;
	position: relative;
}

.notification-cards ul li p {
	line-height: 20px;
	min-height: 40px;
	display: -webkit-box;
	/*-webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;*/
}

.notification-cards ul li .btn-link {
	position: absolute;
	top: 0;
	right: 0;
}

.notification-cards ul li .btn-link i {
	font-size: 15px !important;
}

.notification-cards ul li small {
	font-size: 10px;
	color: var(--BrandDarkGray);
	font-weight: bold;
}

.sop-payment i {
	font-size: 40px;
	color: var(--BrandGreen);
}

/* Footer media queries*/
@media only screen and (max-width: 1025px) and (min-width: 768px) {
	body {
		margin-bottom: 450px !important;
	}
	.footer .tab-device .f-1 {
		width: 40%;
	}

	.footer .tab-device .f-2 {
		width: 30%;
	}

	.footer .tab-device .f-3 {
		width: 30%;
	}

	.footer .tab-device .f-4 {
		width: 100%;
		margin-bottom: 0 !important;
	}
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
	.navbar,
	.our-services,
	.sopGuide,
	.we-offering,
	.testimonial,
	.footer,
	.faq,
	.search-bar {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}

	.carousel .start-per-month {
		left: 270px;
		bottom: 44px;
	}

	.carousel .start-per-month img {
		height: 30px;
	}

	.carousel-caption {
		right: 15%;
		left: 16px !important;
	}

	.navbar {
		position: relative;
	}

	.navbar > .container-fluid {
		justify-content: normal !important;
	}

	.navbar .nav-icons {
		position: absolute;
		top: 18px;
		right: 15px;
	}
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
	.navbar .toast {
		top: auto;
		bottom: 70px;
		right: 10px;
		left: 10px;
		width: 95%;
	}

	.carousel {
		height: 190px !important;
	}

	.carousel-caption {
		top: 0px !important;
		right: 7% !important;
	}

	.innerHead h1 {
		left: 15px;
	}

	.small-hide > a {
		display: none;
	}

	body {
		font-size: 16px !important;
	}

	.dYtQmJ,
	.dobUNI,
	.qFTwe,
	.bYpwJm {
		padding-left: 0px !important;
	}

	.navbar-toggler {
		padding: 0 !important;
		border: none !important;
	}

	.lg-d {
		display: none !important;
	}

	.sm-d {
		display: block !important;
	}

	.deviceTabs {
		display: block;
		position: fixed;
		bottom: 0;
		background: var(--BrandLight);
		-webkit-box-shadow: 0px -5px 6px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px -5px 6px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px -5px 6px 0px rgba(0, 0, 0, 0.25);
		z-index: 9;
	}

	.deviceTabs a {
		padding: 23%;
		text-align: center;
		display: block;
		font-size: 22px;
		color: var(--BrandBlue);
	}

	.deviceTabs .col {
		position: relative;
	}

	.deviceTabs .cart-order {
		top: 15px;
		left: 43px;
		right: auto;
	}

	.home-top-padding {
		padding-top: 62px;
	}
	.whatappChat {
		bottom: 86px;
		right: 13px;
	}
	.footer {
		padding-bottom: 85px;
	}
}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
	body {
		margin-bottom: 930px !important;
	}
	.search-bar {
		top: 60px;
	}

	h1 {
		font-size: 30px !important;
	}

	h2 {
		font-size: 21px !important;
	}

	.btn {
		padding: 8px 10px !important;
	}

	.navbar-brand {
		margin-right: 0 !important;
	}

	.navbar-brand img {
		height: 35px !important;
	}

	.carousel .start-per-month {
		left: 204px;
		bottom: 28px;
	}

	.carousel .start-per-month img {
		height: 28px;
	}

	.carousel-indicators {
		margin-bottom: 0 !important;
	}

	.home-banner .btn {
		font-size: 12px !important;
	}

	.navbar .nav-icons {
		top: 12px;
	}
}

@media (max-width: 375px) {
	h1 {
		font-size: 26px !important;
	}
}

.modal-body-image {
	padding: 5px !important;
}

.height-100 {
	height: 100vh;
}

.disable {
	pointer-events: none;
	cursor: not-allowed;
}
.btn-bell-remove {
	width: 100%;
	padding: 3px !important;
	background: var(--BrandLightGray) !important;
	border: 0 !important;
}
/* loader */

.loading {
	background: #000;
	height: 100vh;
	width: 100vw;
	position: fixed;
	left: 0;
	top: 0;
	opacity: 0.5;
	pointer-events: none;
	z-index: 100001;
	pointer-events: none;
}

#loading-wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

#loading-text {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	color: #999;
	width: 100px;
	height: 30px;
	margin: -7px 0 0 -45px;
	text-align: center;
	font-family: "PT Sans Narrow", sans-serif;
	font-size: 20px;
}

#loading-content {
	display: block;
	position: relative;
	left: 50%;
	top: 50%;
	width: 170px;
	height: 170px;
	margin: -85px 0 0 -85px;
	border: 3px solid #f00;
}

#loading-content:after {
	content: "";
	position: absolute;
	border: 3px solid #0f0;
	left: 15px;
	right: 15px;
	top: 15px;
	bottom: 15px;
}

#loading-content:before {
	content: "";
	position: absolute;
	border: 3px solid #00f;
	left: 5px;
	right: 5px;
	top: 5px;
	bottom: 5px;
}

#loading-content {
	border: 3px solid transparent;
	border-top-color: #4d658d;
	border-bottom-color: #4d658d;
	border-radius: 50%;
	-webkit-animation: loader 2s linear infinite;
	-moz-animation: loader 2s linear infinite;
	-o-animation: loader 2s linear infinite;
	animation: loader 2s linear infinite;
}

#loading-content:before {
	border: 3px solid transparent;
	border-top-color: #d4cc6a;
	border-bottom-color: #d4cc6a;
	border-radius: 50%;
	-webkit-animation: loader 3s linear infinite;
	-moz-animation: loader 2s linear infinite;
	-o-animation: loader 2s linear infinite;
	animation: loader 3s linear infinite;
}

#loading-content:after {
	border: 3px solid transparent;
	border-top-color: #84417c;
	border-bottom-color: #84417c;
	border-radius: 50%;
	-webkit-animation: loader 1.5s linear infinite;
	animation: loader 1.5s linear infinite;
	-moz-animation: loader 2s linear infinite;
	-o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loader {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

#content-wrapper {
	color: #fff;
	position: fixed;
	left: 0;
	top: 20px;
	width: 100%;
	height: 100%;
}

#header {
	width: 800px;
	margin: 0 auto;
	text-align: center;
	height: 100px;
	background-color: #666;
}

#content {
	width: 800px;
	height: 1000px;
	margin: 0 auto;
	text-align: center;
	background-color: #888;
}

.error {
	border: 1px solid red !important;
}

.seperator {
	display: none;
}

@media (max-width: 768px) {
	.seperator {
		display: block;
	}
}

@media print {
	.special {
		display: none;
	}
}

.w-90 {
	width: 100% !important;
	max-width: 90vw !important;
}
